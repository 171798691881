/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const VuesaxBoldAndroid = ({ className }) => {
  return (
    <svg
      className={`vuesax-bold-android ${className}`}
      fill="none"
      height="70"
      viewBox="0 0 70 70"
      width="70"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M31.0833 55.7581V63.5081C31.0833 64.5599 30.2186 65.4247 29.1667 65.4247C28.1148 65.4247 27.25 64.5599 27.25 63.5081V55.7581H31.0833Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M42.75 55.7581V63.5081C42.75 64.5599 41.8852 65.4247 40.8333 65.4247C39.7814 65.4247 38.9167 64.5599 38.9167 63.5081V55.7581H42.75Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M48.5833 32.0833V46.6667C48.5833 49.3227 46.406 51.5 43.75 51.5H26.25C23.5939 51.5 21.4167 49.3227 21.4167 46.6667V32.0833C21.4167 29.4273 23.5939 27.25 26.25 27.25H43.75C46.406 27.25 48.5833 29.4273 48.5833 32.0833Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M13.5833 32.0833V43.75C13.5833 44.8018 12.7185 45.6666 11.6667 45.6666C10.6148 45.6666 9.75 44.8018 9.75 43.75V32.0833C9.75 31.0314 10.6148 30.1666 11.6667 30.1666C12.7185 30.1666 13.5833 31.0314 13.5833 32.0833Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M60.25 32.0833V43.75C60.25 44.8018 59.3852 45.6666 58.3333 45.6666C57.2814 45.6666 56.4167 44.8018 56.4167 43.75V32.0833C56.4167 31.0314 57.2814 30.1666 58.3333 30.1666C59.3852 30.1666 60.25 31.0314 60.25 32.0833Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M45.6667 17.5C45.6667 19.5144 44.0144 21.1667 42.0001 21.1667H27.9999C25.9856 21.1667 24.3333 19.5144 24.3333 17.5C24.3333 11.6065 29.1065 6.83337 35 6.83337C40.8936 6.83337 45.6667 11.6065 45.6667 17.5Z"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};
